import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { PRIMARY_COLOR, screenLessThan } from '../../shared/styles';
import { ArrowDown } from '../../../static/customised-svgs/arrow-down';
import { ArrowUp } from '../../../static/customised-svgs/arrow-up';
import { Globe } from '../../../static/customised-svgs/globe';
const LanguageToggle = ({ offset, isMobile }) => {
  const { language, languages, originalPath } = useI18next();
  const names = languages
    .map((lang) => {
      const languageNames = new Intl.DisplayNames([lang], {
        type: 'language',
      });
      return languageNames.of(lang);
    })
    .reduce((acc, name, index) => {
      acc[languages[index]] = name;
      return acc;
    }, {});

  const [isActive, setIsActive] = useState(false);

  const dropdownHandler = () => setIsActive(!isActive);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
      <ToggleContainer>
        {isActive ? (
          <DropdownWrapper>
            {languages.map((languageKey) => (
              <ItemWrapper
                key={languageKey}
                className={language === languageKey ? 'active-lang' : ''}
                to={originalPath}
                onClick={dropdownHandler}
                language={languageKey}
              >
                {names[languageKey]}
              </ItemWrapper>
            ))}
          </DropdownWrapper>
        ) : null}
        <ToggleWrapper
          offset={offset}
          isMobile={isMobile}
          onClick={dropdownHandler}
        >
          <Globe />
          <LanguageName isActive={isActive}>
            {names[language]}
            {isActive ? <ArrowUp /> : <ArrowDown />}
          </LanguageName>
        </ToggleWrapper>
      </ToggleContainer>
    </OutsideClickHandler>
  );
};

const LanguageName = styled.span`
  color: ${(props) => (props.isActive ? PRIMARY_COLOR : '')};
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;

  ${screenLessThan('1150px')} {
    margin: auto 0px 0px 12px;
  }
`;

const ToggleContainer = styled.div`
  position: relative;
`;

const ToggleWrapper = styled.div`
  align-items: center;
  border-right: 1px solid #cdcdce;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 15px;
  ${({ isMobile, offset }) =>
    isMobile
      ? css``
      : offset > 0
      ? css`
          border-left: 1px solid #cdcdce;
        `
      : ''}

  ${screenLessThan('1150px')} {
    justify-content: center;
    border-right: 0px;
    margin-right: 0px;
  }

  :hover {
    color: ${PRIMARY_COLOR};
  }
  svg {
    margin-bottom: 2px;
    margin-left: 5px;
  }
  svg.globe {
    margin: auto;
  }
  :hover {
    color: ${PRIMARY_COLOR};
    svg.globe path {
      fill: ${PRIMARY_COLOR};
    }
    svg.arrow-down path {
      stroke: ${PRIMARY_COLOR};
    }
  }
`;

const DropdownWrapper = styled.div`
  background-color: #ffffff;
  bottom: ${({ isMobile }) => (isMobile ? '35px' : 'auto')};
  box-shadow: 0px 3px 10px 0px rgb(38 107 193 / 8%);
  left: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  padding: 10px;
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? 'auto' : '35px')};
  z-index: 1;

  .active-lang {
    background: #ff7d6b14 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: ${PRIMARY_COLOR};
  }
`;

const ItemWrapper = styled(Link)`
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 1px;
  padding: 5px 30px;

  &:hover {
    background: #ff7d6b14 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: ${PRIMARY_COLOR};
  }

  text-decoration: none;
  &:hover &:focus &:active {
    text-decoration: none;
  }
`;

LanguageToggle.propTypes = {
  isMobile: PropTypes.bool,
  offset: PropTypes.number,
};

export default LanguageToggle;
