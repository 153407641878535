import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../Button';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_REGULAR, screenLessThan } from '../../shared/styles';

const NavbarSignupButton = () => {
  const { t } = useTranslation();
  return (
    <StyledRoundButton onClick={RedirectToSSOSignUp}>
      {t('get_started')}
    </StyledRoundButton>
  );
};

const StyledRoundButton = styled(Button)`
  font-size: 14px;
  padding: 0 0.8rem;
  ${FONT_REGULAR}
  ${screenLessThan('1600px')} {
    height: 2.2rem;
  }
  ${screenLessThan('1142px')} {
    margin-right: 10px;
  }
`;

export default NavbarSignupButton;
