import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';

import {
  FONT_BOLD,
  PRIMARY_COLOR,
  upAndDownSlowMove,
} from '../../shared/styles';

const HeaderDropdownMenuCard = ({
  currentMenuContent,
  handleActiveMenu,
  item,
}) => {
  const { t } = useTranslation();
  return (
    <MenuCardWrapper
      item
      hover={item.link ?? null}
      onClick={item?.link ? () => handleActiveMenu(item.link) : null}
      sm={4}
    >
      {item.iconSrc && <ProductImage src={item.iconSrc} alt="dropdown" />}
      <Stack>
        {currentMenuContent.isComingSoon && (
          <DropdownComingSoon>
            {t('header-dropdown.coming-soon')}
          </DropdownComingSoon>
        )}

        <MenuCardTitle>{t(item.title)}</MenuCardTitle>
        <MenuCardSubtitle>{t(item.content)}</MenuCardSubtitle>
      </Stack>
    </MenuCardWrapper>
  );
};

export default HeaderDropdownMenuCard;

HeaderDropdownMenuCard.propTypes = {
  item: PropTypes.object,
  currentMenuContent: PropTypes.object,
  handleActiveMenu: PropTypes.func,
};

const DropdownComingSoon = styled.div`
  background-color: rgba(250, 97, 70, 0.07);
  border-radius: 4px;
  color: ${PRIMARY_COLOR};
  display: none;
  font-size: 12px;
  padding: 3px 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: fit-content;
`;

const MenuCardWrapper = styled(Grid)`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  display: flex;
  padding: 0 0 1rem;
  position: relative;
  &:hover {
    div${DropdownComingSoon} {
      display: block;
    }
    background-color: #fafafa;
    img {
      animation: ${upAndDownSlowMove} 1.5s linear infinite;
    }
  }
`;

const ProductImage = styled.img`
  margin-right: 1rem;
  padding: 1rem;
  width: 1.7rem;
`;

const MenuCardTitle = styled.p`
  margin: 10px 1px 0;
  ${FONT_BOLD}
`;

const MenuCardSubtitle = styled.p`
  font-size: 12px;
  line-height: 1.7;
  margin: 0 1rem 0 0;
  width: 90%;
`;
