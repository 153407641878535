import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from './Button';
import { leftRightMove, leftRightMoveMirrored } from '../../shared/styles';
import arrowSrc from '../../../static/svgs/home/arrow-right-white.svg';

/**
 * Styled Button Component with a moving arrow at right end
 */
const ButtonWithArrow = ({ children, shouldFlipInPlace, ...props }) => {
  const { i18n } = useTranslation();
  const isLTR = i18n.dir() === 'ltr';
  const isAfter = isLTR || shouldFlipInPlace;
  return (
    <StyledButtonWithArrow
      shouldFlipInPlace={shouldFlipInPlace && !isLTR}
      {...props}
    >
      {!isAfter && (
        <img className="arrow before" src={arrowSrc} alt="moving-arrow" />
      )}
      {children}
      {isAfter && (
        <img className="arrow after" src={arrowSrc} alt="moving-arrow" />
      )}
    </StyledButtonWithArrow>
  );
};

const StyledButtonWithArrow = styled(Button)`
  > img.arrow {
    width: 17px;
    height: 12px;
  }
  > img.before {
    margin-right: 15px;
  }
  > img.after {
    margin-left: 15px;
    transform: ${({ shouldFlipInPlace }) =>
      shouldFlipInPlace ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
  &:hover {
    > img.arrow {
      animation: ${({ shouldFlipInPlace }) =>
          shouldFlipInPlace ? leftRightMoveMirrored : leftRightMove}
        0.7s linear infinite;
    }
  }
`;

ButtonWithArrow.propTypes = {
  children: PropTypes.node,
  shouldFlipInPlace: PropTypes.bool,
};

ButtonWithArrow.defaultProps = {
  className: 'btn__get-started',
};

export default ButtonWithArrow;
