import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import rtlPlugin from 'stylis-plugin-rtl';

import './index.css';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import IdlePopup from '../../../components/IdlePopup';
import { HEADER_HEIGHT, screenLessThan } from '../../styles';

const PipeDrive = loadable(() => import('../../analytics/PipeDrive'));

const Layout = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <StyleSheetManager stylisPlugins={i18n.dir() === 'rtl' ? [rtlPlugin] : []}>
      <MainContainer dir={i18n.dir()} lang={i18n.language}>
        <Header />

        {children}
        <Footer />
        <PipeDrive />
        <IdlePopup />
      </MainContainer>
    </StyleSheetManager>
  );
};
const MainContainer = styled.main`
  position: relative;
  top: ${HEADER_HEIGHT};
  transition: all 1s ease-out;
  width: 100%;
  ${screenLessThan('1000px')} {
    top: ${HEADER_HEIGHT};
  }
`;
Layout.propTypes = {
  children: PropTypes.object,
};

export default Layout;
