import React from 'react';
import { ROUTES } from '../../shared/routes';
import {
  BIZ_SOCIAL_LINKS,
  BIZ_STATUS_PAGE,
  SALE_TEAM_MAIL,
} from '../../config';
import fbImg from '../../../static/svgs/social/fb.svg';
import instaImg from '../../../static/svgs/social/insta.svg';
import linkedinImg from '../../../static/svgs/social/linkedin.svg';
import twitterImg from '../../../static/svgs/social/twitter.svg';
import youtubeImg from '../../../static/svgs/social/youtube.svg';

export const footerLinks = {
  contactus: {
    footerTitle: 'footer.contact.title',
    footerSubLinks: [
      {
        text: 'header-menu.get_in_touch',
        path: ROUTES.getInTouch,
      },
      {
        text: SALE_TEAM_MAIL,
        isMail: true,
      },
    ],
    isFirstColumn: true,
  },
  biz: {
    footerTitle: 'footer.biz.title',
    footerSubLinks: [
      { text: 'footer.biz.pricing', path: ROUTES.pricing },
      { text: 'footer.biz.request_a_demo', path: ROUTES.requestDemo },
      { text: 'footer.biz.security', path: ROUTES.security },
      { text: 'footer.biz.about_us', path: ROUTES.aboutUs },
    ],
  },

  bizapps: {
    footerTitle: 'footer.bizapps.title',
    footerSubLinks: [
      {
        text: 'footer.bizapps.features_list',
        path: ROUTES.features,
      },
      {
        text: 'footer.bizapps.invoice_software',
        path: ROUTES.featuresInvoice,
      },
      {
        text: 'footer.bizapps.estimate_software',
        path: ROUTES.featuresEstimates,
      },
      {
        text: 'footer.bizapps.customer_management',
        path: ROUTES.featuresCustomers,
      },
      {
        text: 'footer.bizapps.inventory_management',
        path: ROUTES.featuresInventory,
      },
      {
        text: 'footer.bizapps.purchase_order_management',
        path: ROUTES.featuresPO,
      },
      {
        text: 'footer.bizapps.accounting_management',
        path: ROUTES.featuresAccounting,
      },
    ],
  },
  legal: {
    footerTitle: 'footer.legal.title',
    footerSubLinks: [
      { text: 'footer.legal.help_center' },
      { text: 'footer.legal.faq', path: ROUTES.faq },
      {
        text: 'footer.legal.status',
        path: BIZ_STATUS_PAGE,
        isExternal: true,
      },
      { text: 'footer.legal.privacy_policy', path: ROUTES.privacyPolicy },
      { text: 'footer.legal.cookie_policy', path: ROUTES.cookiePolicy },
    ],
  },
};

export const socialLinks = [
  {
    link: BIZ_SOCIAL_LINKS.facebook,
    Icon: <img src={fbImg} alt="facebook" />,
  },
  {
    link: BIZ_SOCIAL_LINKS.linkedin,
    Icon: <img src={linkedinImg} alt="linkedin" />,
  },
  {
    link: BIZ_SOCIAL_LINKS.instagram,
    Icon: <img src={instaImg} alt="instagram" />,
  },
  {
    link: BIZ_SOCIAL_LINKS.twitter,
    Icon: <img src={twitterImg} alt="twitter" />,
  },
  {
    link: BIZ_SOCIAL_LINKS.youtube,
    Icon: <img src={youtubeImg} alt="youtube" />,
  },
];
