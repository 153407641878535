import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './Links.styles';

/**
 * Link to open mail compose
 * @param {String} mail Mail ID
 * You can wrap this component to the anything custom that needs to act as link
 * If not; by default it will wrap in mail id itself
 */
const MailLink = ({ mail, children, ...props }) => (
  <StyledLink {...props} href={`mailto:${mail}`}>
    {children ?? mail}
  </StyledLink>
);

MailLink.propTypes = {
  mail: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default MailLink;
