import { ROUTES } from '../../shared/routes';
import {
  PurchaseOrders,
  ExpiryDate,
  Accounting,
} from '../../../static/svgs/featuresBuiltForYou';
import clientSrc from '../../../static/svgs/firstSection/Clients.svg';
import estimatesSrc from '../../../static/svgs/firstSection/Estimates.svg';
import inventorySrc from '../../../static/svgs/firstSection/Inventory.svg';
import invoiceSrc from '../../../static/svgs/firstSection/Invoicing.svg';
import Enterprise from '../../../static/svgs/navbar/enterprise.svg';
import Freelancers from '../../../static/svgs/navbar/freelancers.svg';
import NeedHelp from '../../../static/svgs/navbar/need-more-help.svg';
import NonProfit from '../../../static/svgs/navbar/non-profit.svg';
import SmallMedium from '../../../static/svgs/navbar/small-medium.svg';
import Startup from '../../../static/svgs/navbar/startup.svg';

const navFeaturesArray = [
  {
    title: `header-dropdown.freelancers`,
    content: `header-dropdown.freelancers_text`,
    iconSrc: Freelancers,
  },
  {
    title: `header-dropdown.non_profit`,
    content: `header-dropdown.non_profit_text`,
    iconSrc: NonProfit,
  },
  {
    title: `header-dropdown.startups`,
    content: `header-dropdown.startups_text`,
    iconSrc: Startup,
  },
  {
    title: `header-dropdown.enterprise`,
    content: `header-dropdown.enterprise_text`,
    iconSrc: Enterprise,
  },
  {
    title: `header-dropdown.small_medium`,
    content: `header-dropdown.small_medium_text`,
    iconSrc: SmallMedium,
  },
];

const navSolutionsArray = [
  {
    title: `invoices`,
    content: `header-dropdown.invoice_text`,
    iconSrc: invoiceSrc,
    link: ROUTES.featuresInvoice,
  },
  {
    title: `po`,
    content: `header-dropdown.po_text`,
    iconSrc: PurchaseOrders,
    link: ROUTES.featuresPO,
  },
  {
    title: `inventory`,
    content: `header-dropdown.inventory_text`,
    iconSrc: inventorySrc,
    link: ROUTES.featuresInventory,
  },
  {
    title: `estimates`,
    content: `header-dropdown.estimate_text`,
    iconSrc: estimatesSrc,
    link: ROUTES.featuresEstimates,
  },
  {
    title: `leads`,
    content: `header-dropdown.leads_text`,
    iconSrc: clientSrc,
    link: ROUTES.featuresCustomers,
  },
  {
    title: `accounting`,
    content: `header-dropdown.accounting_text`,
    iconSrc: Accounting,
    link: ROUTES.featuresAccounting,
  },
];

export const navbarMenu = [
  {
    menuKey: 'solution',
    title: 'header-menu.product',
    dropdown: navSolutionsArray,
  },
  {
    menuKey: 'pricing',
    title: 'header-menu.pricing',
    link: ROUTES.pricing,
  },
  {
    menuKey: 'company_nature',
    title: 'header-menu.industries',
    dropdown: navFeaturesArray,
  },
  {
    menuKey: 'about_us',
    title: 'header-menu.about_us',
    link: ROUTES.aboutUs,
  },
  {
    menuKey: 'contact_us',
    title: 'header-menu.contact_us',
    link: ROUTES.contactUs,
  },
];

export const navbarMobileMenu = [
  ...navbarMenu,
  {
    menuKey: 'request-demo',
    title: 'header-menu.request_demo',
    link: ROUTES.requestDemo,
  },
  {
    menuKey: 'get_in_touch',
    title: 'header-menu.get_in_touch',
    link: ROUTES.getInTouch,
  },
];

export const navbarDropdownLinks = [
  {
    title: 'product_tour',
    link: ROUTES.requestDemo,
  },
  {
    title: 'view_all_features',
    link: ROUTES.features,
  },
  {
    title: 'faq',
    link: ROUTES.faq,
  },
  {
    title: 'help-centre',
    link: ROUTES.getInTouch,
  },
  {
    title: 'security',
    link: ROUTES.security,
  },
];

export const navbarDropdownFooter = [
  {
    icon: ExpiryDate,
    title: 'header-menu.request_demo',
    content: 'header-menu.request_demo_content',
    link: ROUTES.requestDemo,
  },
  {
    icon: NeedHelp,
    title: 'header-menu.need_help',
    content: 'header-menu.need_help_content',
    link: ROUTES.getInTouch,
  },
];

export const navbarMenuDropdown = {
  solution: {
    title: `header-menu.products`,
    submenus: navSolutionsArray,
    layout: {
      wrapperClass: 'navbar-menu-dropdown-wrapper',
      imagesClassName: 'product-menu',
    },
  },
  company_nature: {
    title: `header-dropdown.company_nature`,
    submenus: navFeaturesArray,
    isComingSoon: true,
    layout: {
      wrapperClass: 'navbar-menu-dropdown-wrapper',
      imagesClassName: 'industries-menu',
    },
  },
};
