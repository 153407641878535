import { API_URL } from '../../config';

const SERVER_ERROR =
  "Something is technically wrong. Thanks for noticing - we're going to fix it up and have things back to normal soon.";

const requestOptions = (data = {}) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data }),
  };
};

export const getStartedAction = async (
  dispatchTypes,
  dispatch,
  formData = {}
) => {
  dispatch({ type: dispatchTypes.FETCH_START });
  try {
    const { data } = await fetch(
      API_URL + '/website/api/trial/get_started',
      requestOptions(formData)
    );
    dispatch({
      type: dispatchTypes.FETCH_SUCCESS,
      payload: { ...data },
    });
  } catch (error) {
    const { data } = error.response || {};
    dispatch({
      type: dispatchTypes.FETCH_FAIL,
      payload: data?.message || SERVER_ERROR,
    });
  }
};
