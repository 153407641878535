import React, { useState, useReducer } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../Button';
import { actionTypes, initialState, reducer } from './reducerHook';
import { getStartedAction } from '../../shared/service';
import { SIGN_UP_URL } from '../../config';
import { screenLessThan, slideInFromRight } from '../../shared/styles';

const EmailInput = ({ isHeader }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loading } = state;

  const handleInput = ({ target }) => {
    setEmail(target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getStartedAction(actionTypes, dispatch, { email });
    setTimeout(() => {
      window.location.assign(`${SIGN_UP_URL}&input_email=${email}`);
    }, 500);
  };

  return (
    <EmailInputWrapper className={isHeader ? 'header-input' : 'footer-input'}>
      <form
        action={`${SIGN_UP_URL}&input_email=${email}`}
        onSubmit={handleSubmit}
        className="form__get-started"
      >
        <input
          type="email"
          value={email}
          placeholder={t('home.form.placeholder')}
          onBlur={handleInput}
          onChange={handleInput}
          required
        />
        <EmailInputButton disabled={loading}>
          {t('get_started')}
        </EmailInputButton>
      </form>
    </EmailInputWrapper>
  );
};

const EmailInputWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  max-width: 100%;
  &.footer-input {
    margin-bottom: 15px;
    margin-top: 25px;
    padding: 4px 5px;
    > form > input {
      padding: 15px;
    }
  }
  &.header-input {
    margin-left: 10px;
    > form > input {
      animation: ${slideInFromRight} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      padding: 3px 15px;
    }
    ${screenLessThan('1600px')} {
      button {
        height: 2.5rem;
        z-index: 10;
      }
    }
  }
  > form {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  > form > input {
    border: none;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
  }

  ::placeholder {
    color: #d8d8d8;
  }
`;

const EmailInputButton = styled(Button)`
  height: unset;
`;

EmailInput.propTypes = {
  isHeader: PropTypes.bool,
};

export default EmailInput;
