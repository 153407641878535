import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FONT_MEDIUM, PRIMARY_COLOR } from '../../shared/styles';

const Button = styled.button`
  ${FONT_MEDIUM}
  align-items: center;
  background-color: ${PRIMARY_COLOR};
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 3.3rem;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.85;
  }

  :disabled {
    background-color: ${PRIMARY_COLOR};
    color: #fff;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
