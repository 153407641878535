import { css } from 'styled-components';
import { screenLessThan } from './MediaQueries';
/**
 * Spacing (padding) of left and right side of contents in screen
 */

export const SPACE_MD = '5%';
export const SPACE_LG = '6%';

export const SCREEN_PAD = css`
  padding-left: ${SPACE_LG};
  padding-right: ${SPACE_LG};
  ${screenLessThan('1000px')} {
    padding-left: ${SPACE_MD};
    padding-right: ${SPACE_MD};
  }
`;
