export const FEATURE_ROUTES = {
  featuresInvoice: 'invoice-management-software-for-business',
  featuresEstimates: 'estimates-management-software-for-business',
  featuresLeads: 'lead-management-software-for-businesses',
  featuresDeals: 'deals-management-software-for-business',
  featuresCustomers: 'customer-management-crm-software-for-businesses',
  featuresPO: 'purchase-order-management-system-for-sme',
  featuresInventory: 'inventory-management-software-for-business',
  featuresVendor: 'vendor-management-software-for-businesses',
  featuresAccounting: 'accounting-and-billing-software-for-business',
};

export const ROUTES = {
  home: '/',
  homeERP: '/erp-software-for-business',
  pricingDepricated: '/pricing',
  pricing: '/pricing',
  security: '/security',
  cookiePolicy: '/cookie-policy',
  privacyPolicy: '/privacy-policy',
  requestDemo: '/request-demo',
  faq: '/faqs',
  features: '/features',
  contactUs: '/contact-us',
  getInTouch: '/get-in-touch',
  getInTouchOmanEvent: '/get-in-touch-oman-event',
  status: '/status',
  featuresFullView: '/features',
  aboutUs: '/about-us',

  featuresInvoice: `/features/${FEATURE_ROUTES.featuresInvoice}`,
  featuresEstimates: `/features/${FEATURE_ROUTES.featuresEstimates}`,
  featuresLeads: `/features/${FEATURE_ROUTES.featuresLeads}`,
  featuresDeals: `/features/${FEATURE_ROUTES.featuresDeals}`,
  featuresCustomers: `/features/${FEATURE_ROUTES.featuresCustomers}`,
  featuresPO: `/features/${FEATURE_ROUTES.featuresPO}`,
  featuresInventory: `/features/${FEATURE_ROUTES.featuresInventory}`,
  featuresVendor: `/features/${FEATURE_ROUTES.featuresVendor}`,
  featuresAccounting: `/features/${FEATURE_ROUTES.featuresAccounting}`,
};
