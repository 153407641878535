import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, Popover, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useI18next } from 'gatsby-plugin-react-i18next';
import HeaderDropdownMenuCard from './HeaderDropdownMenuCard';
import {
  navbarDropdownFooter,
  navbarDropdownLinks,
  navbarMenuDropdown,
} from './Header.helper';
import { CONTACT_US_NUMBERS, SALE_TEAM_MAIL } from '../../config';
import {
  FONT_BOLD,
  PRIMARY_COLOR,
  screenLessThan,
  slideInFromTop,
} from '../../shared/styles';

const popoverPosition = (dir) => {
  return {
    anchorReference: 'anchorPosition',
    anchorPosition: { top: 90, left: dir === 'rtl' ? 250 : 100 },
    dir: dir,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };
};

const DropdownLayout = ({ activeMenu, setActiveMenu }) => {
  const { t, navigate, i18n } = useI18next();
  const currentMenuContent = navbarMenuDropdown[activeMenu];
  const handleActiveMenu = (link) => {
    link && navigate(link);
    setActiveMenu('');
  };
  const open = Boolean(activeMenu);
  const handleClose = () => {
    setActiveMenu('');
  };
  return (
    <LayoutPopover
      disableScrollLock={false} //testing for windows pc to see if the scrollbar disappears
      PaperProps={{ className: currentMenuContent.layout.wrapperClass }}
      open={open}
      onClose={handleClose}
      {...popoverPosition(i18n.dir())}
    >
      <DropdownTitle>{t(currentMenuContent.title)}</DropdownTitle>
      <Stack gap={2}>
        <Stack direction="row">
          <Grid container columns={8.5} rowSpacing={2}>
            {currentMenuContent?.submenus?.map((item, index) => (
              <HeaderDropdownMenuCard
                currentMenuContent={currentMenuContent}
                handleActiveMenu={handleActiveMenu}
                item={item}
                key={index}
              />
            ))}
          </Grid>
          <DropdownMenuRightColumn gap={1}>
            {navbarDropdownLinks.map((items, index) => (
              <DropdownMenuRightLink
                key={index}
                onClick={() =>
                  items?.link ? handleActiveMenu(items.link) : null
                }
              >
                {t(`header-dropdown.${items.title}`)}
              </DropdownMenuRightLink>
            ))}
          </DropdownMenuRightColumn>
        </Stack>

        <DropdownMenuFooterSection direction="row" gap={7}>
          {navbarDropdownFooter.map((items, index) => (
            <DropdownMenuFooterButton
              onClick={() =>
                items?.link ? handleActiveMenu(items.link) : null
              }
              key={index}
            >
              <DropdownBottomImage src={items.icon} alt="dropdown" />

              <div>
                <DropdownItemsTitle>{t(items.title)}</DropdownItemsTitle>
                <DropdownItemsSubtitle>
                  {t(items.content, {
                    email: SALE_TEAM_MAIL,
                    phone: CONTACT_US_NUMBERS.QATAR,
                  })}
                </DropdownItemsSubtitle>
              </div>
            </DropdownMenuFooterButton>
          ))}
        </DropdownMenuFooterSection>
      </Stack>
    </LayoutPopover>
  );
};

const LayoutPopover = styled(Popover)`
  animation: ${slideInFromTop} 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  ${screenLessThan('1142px')} {
    display: none;
  }
  .navbar-menu-dropdown-wrapper {
    border-radius: 30px;
    padding: 2rem 2.5rem 2.5rem;
    width: 900px;
  }
`;

const DropdownTitle = styled.p`
  color: #a9aebd;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  ${FONT_BOLD}
`;

const DropdownBottomImage = styled.img`
  margin-bottom: auto;
  margin-right: 1rem;
  width: 2.3rem;
`;

const DropdownMenuRightColumn = styled(Stack)`
  border-left: solid 1px #e8ecee;
  justify-content: center;
  margin-right: 2.5rem;
  padding-left: 3rem;
  width: 20%;
`;

const DropdownMenuRightLink = styled(Link)`
  color: black;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1.4rem;
  text-decoration: none;
  ${FONT_BOLD}
  :hover {
    color: ${PRIMARY_COLOR};
  }
`;

const DropdownMenuFooterSection = styled(Stack)`
  border-top: 1px solid #e8ecee;
`;

const DropdownMenuFooterButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  text-align: left;

  &:hover {
    background-color: #fafafa;
    border-radius: 4px;
  }
`;

const DropdownItemsTitle = styled.p`
  margin-bottom: 2px;
  ${FONT_BOLD}
`;

const DropdownItemsSubtitle = styled.p`
  font-size: 12px;
  line-height: 1.7;
  margin: 0 1rem 0 0;
  width: 90%;
`;

DropdownLayout.propTypes = {
  activeMenu: PropTypes.string,
  setActiveMenu: PropTypes.func,
};

export default DropdownLayout;
