import { keyframes } from 'styled-components';

/**
 * Animation Keyframes
 */

export const slideInFromLeft = keyframes`
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
`;

export const slideInFromRight = keyframes`
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const upAndDownSlowMove = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
`;

export const leftRightMove = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-2px);
  }
`;
export const leftRightMoveMirrored = keyframes`
  0%, 100% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg) translateX(-2px);
  }
`;

export const slideInFromTop = keyframes`
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
`;

export const shakeVertical = keyframes`
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-5px);
  }
  20%,
  40%,
  60% {
    transform: translateY(5px);
  }
  80% {
    transform: translateY(5px);
  }
  90% {
    transform: translateY(-5px);
  }
`;

export const pulse = keyframes`
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;

export const jumpYAxis = keyframes`
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
`;
