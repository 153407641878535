/**
 * Helper to download a PDF file from Server link
 * @param {String} link PDF Link to download
 * @param {String} name Name for file to download
 */
export const onDownloadPDF = (link, name = 'Biz_File.pdf') => {
  // using Java Script method to get PDF file
  fetch(link).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = name;
      alink.click();
    });
  });
};
