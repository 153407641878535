import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { CallLink } from '../Links';
import {
  FONT_BOLD,
  PRIMARY_COLOR,
  screenGreaterThan,
  screenLessThan,
  SCREEN_PAD,
} from '../../shared/styles';

const MobileStyles = css`
  display: flex;
  ${screenLessThan('1142px')} {
    display: none;
  }
`;
export const HeaderBizLogo = styled.img`
  height: 45px;
  margin-left: -5px;
  width: 107px;
`;
export const HeaderContainer = styled.div`
  align-items: center;
  background: #fafafa;
  ${(props) =>
    props.showBoxShadow &&
    css`
      box-shadow: 0px 3px 10px 0px rgb(38 107 193 / 8%);
    `}
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-out;
  width: 100%;
  z-index: 200;
`;
export const HeaderInnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  padding-top: 18px;
  width: 100%;
  ${SCREEN_PAD}
`;
export const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
`;
export const HeaderRight = styled.div`
  align-items: center;
  flex: 1.5;
  justify-content: flex-end;
  text-align: end;
  ${MobileStyles}
  .lang-btn {
    background: transparent;
    border: 1px transparent;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: -5px;
    ${FONT_BOLD}
  }
`;
export const HeaderMenu = styled.div`
  font-size: 14px;
  padding: 0 50px;
  text-align: start;
  ${MobileStyles}
`;
export const MobileMenuRight = styled.div`
  align-items: center;
  display: flex;
  ${screenGreaterThan('1142px')} {
    display: none;
  }
`;

export const NavbarLoginText = styled(Link)`
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  text-decoration: none;
  :hover {
    color: ${PRIMARY_COLOR};
  }
`;

export const PhoneNumber = styled(CallLink)`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

export const CallImg = styled.img`
  margin-right: 1rem;
  width: 17px;
`;
