import React from 'react';

// eslint-disable-next-line import/no-unused-modules, react/prop-types
export const Globe = ({ color = '#181818' }) => (
  <svg
    className="globe"
    xmlns="http://www.w3.org/2000/svg"
    width="15.157"
    height="15.157"
    viewBox="0 0 15.157 15.157"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-2 -2)">
      <path
        id="globe-2"
        d="M9.579,2a7.579,7.579,0,1,0,7.579,7.579A7.579,7.579,0,0,0,9.579,2Zm0,1.516a6.207,6.207,0,0,1,1.357.159,1.978,1.978,0,0,1-.591.758c-.167.129-.349.235-.531.349a3.456,3.456,0,0,0-1.4,1.266,4.919,4.919,0,0,0-.47,2.5c0,1.031,0,1.637-.72,2.175-1.038.811-2.622.356-3.607-.053a6.313,6.313,0,0,1-.1-1.091A6.063,6.063,0,0,1,9.579,3.516ZM4.274,12.458a6.381,6.381,0,0,0,1.516.2,3.789,3.789,0,0,0,2.38-.758,3.755,3.755,0,0,0,1.3-3.365,3.607,3.607,0,0,1,.28-1.773,2.167,2.167,0,0,1,.849-.69,7.389,7.389,0,0,0,.7-.462A3.448,3.448,0,0,0,12.352,4.2a6.063,6.063,0,0,1,2.531,2.44c-1.084.152-2.622.508-2.925,1.917a5.305,5.305,0,0,0-.106,1.023,2.221,2.221,0,0,1-.22,1.114l-.076.129a2.639,2.639,0,0,0-.3,3.031c.091.159.189.311.288.462a1.735,1.735,0,0,1,.394.818,5.979,5.979,0,0,1-2.365.508,6.063,6.063,0,0,1-5.305-3.183Zm9.041,1.91a5.146,5.146,0,0,0-.477-.864c-.083-.121-.167-.243-.243-.371-.3-.515-.189-.758.288-1.516l.076-.129a3.615,3.615,0,0,0,.409-1.842,4.107,4.107,0,0,1,.068-.758c.121-.553,1.3-.7,2.023-.758a6.017,6.017,0,0,1-2.167,6.275Z"
        fill={color}
      />
    </g>
  </svg>
);
