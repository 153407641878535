export const actionTypes = {
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
};

export const initialState = { loading: false };

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_START:
      return { ...state, loading: true };

    case actionTypes.FETCH_SUCCESS:
      return { ...state, data: payload, loading: true, error: null };

    case actionTypes.FETCH_FAIL:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
