import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './Links.styles';

/**
 * Link that open a new tab
 * @param {String} url page link
 * You can wrap this component to the anything custom that needs to act as link
 * If not; by default it will wrap in link itself
 */
const ExternalLink = ({ url, children, ...props }) => (
  <StyledLink {...props} href={url} rel="noopener noreferrer" target="_blank">
    {children ?? url}
  </StyledLink>
);

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ExternalLink;
