import React from 'react';

// eslint-disable-next-line import/no-unused-modules, react/prop-types
export const ArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.295"
    height="5.221"
    viewBox="0 -3 7.295 .11"
  >
    <path
      id="Down_Arrow"
      data-name="Down Arrow"
      d="M.88,1.52C1,1.68,3.17,5.23,3.49,4.66,4.18,3.43,5.5,2.52,6.12,1.27"
      transform="scale(1, -1) translate(0 -0.20)"
      fill="none"
      stroke="#fa6146"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
