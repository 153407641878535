import glowbottle from '../static/svgs/clients-imgs/Glow-icon.svg';
import shift from '../static/svgs/clients-imgs/inara.svg';
import inara from '../static/svgs/clients-imgs/shift.svg';
import amazon from '../static/svgs/home/amazon.svg';
import aws from '../static/svgs/home/aws.svg';
import gartner from '../static/svgs/home/gartner.svg';
import iso from '../static/svgs/home/iso.svg';
import secure from '../static/svgs/home/secure.svg';

export const CLIENT_LIST = [shift, glowbottle, inara];

export const PARTNERS_AND_CERTIFICATIONS_LIST = [
  gartner,
  iso,
  secure,
  amazon,
  aws,
];

export const BIZ_APP_URL = process.env.GATSBY_BIZAPP_URL;
export const API_URL = process.env.GATSBY_API_URL;

const SSO_URL = process.env.GATSBY_SSO_URL;

export const CALENDLY_URL = `https://calendly.com/biz_sales/biz_demo`;
export const BIZ_STATUS_PAGE = 'https://bizapp.statuspage.io/';
export const PIPEDRIVE_CONTACT_FORM_URL =
  'https://webforms.pipedrive.com/f/33aTFyNCeRc4MNkFAlyuhffucPXOX3uxAZmMP44hLq2Bhd5LiVndif1gKG59a0nhV';
export const PIPEDRIVE_CONTACT_OMAN_EVENT_URL =
  'https://webforms.pipedrive.com/f/1yssQWrtOwb0hIslcGQOLe0USQuQ54e4M80b0pDo6pdAIkLQLHIOsWC2WiJwXdzKX';

export const SALE_TEAM_MAIL = 'sales@bizapp.co';
export const SIGN_IN_URL = `${SSO_URL}/authentication/login`;
export const SIGN_UP_URL = `${SSO_URL}/authentication/get-started?utm_source=bizapp&utm_medium=webapp`;

export const PDF_BROCHURE_DOWNLOAD_LINK =
  'https://bizapp-production.s3.eu-central-1.amazonaws.com/images/documents/Biz-+Portfolio+New.pdf';

export const BIZ_LINKEDIN_JOB_LINK =
  'https://www.linkedin.com/company/thebizapp/jobs/';

export const BIZ_SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/thebizapp',
  instagram: 'https://www.instagram.com/the.biz.app/',
  twitter: 'https://twitter.com/thebizapp',
  linkedin: 'https://www.linkedin.com/company/thebizapp',
  youtube: 'https://www.youtube.com/channel/UC3in58nJ5Q4K1tfIsIzMJuQ',
};

export const CONTACT_US_NUMBERS = {
  QATAR: '+974 3331 1989',
  INDIA: '+91 87798 36702',
  SAUDI: '+966 54 710 3783',
};

export const COPYRIGHT_YEAR = new Date().getFullYear();
export const PRIVACY_POLICY_LAST_UPDATED_DATE = 'March 3, 2020';
