import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import NavbarSignInButton from './NavbarSignInButton';
import { navbarMobileMenu } from './Header.helper';
import {
  FONT_MEDIUM,
  PRIMARY_COLOR,
  RightMobileNavbarMediaQueries,
  screenGreaterThan,
  slideInFromLeft,
} from '../../shared/styles';

const MobileMenu = ({ setMobileMenu }) => {
  const { t, navigate } = useI18next();

  const handleMobileMenuClick = (expandMenuItem) => {
    if (expandMenuItem?.link) {
      navigate(expandMenuItem.link);
      setMobileMenu(false);
    }
  };

  return (
    <MenuContainer>
      {navbarMobileMenu.map((item, key) =>
        item?.dropdown ? (
          <MobileMenuAccordion square key={`_id-${key}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="mobile-header-menu"
              id="mobile-header-menu"
            >
              {t(item.title)}
            </AccordionSummary>
            <AccordionDetails>
              {item?.dropdown?.map((expandMenuItem, i) => (
                <MobileMenuItem
                  key={`_id-${i}`}
                  $hover={expandMenuItem?.link ?? null}
                  onClick={() => handleMobileMenuClick(expandMenuItem)}
                >
                  {t(expandMenuItem.title)}
                </MobileMenuItem>
              ))}
            </AccordionDetails>
          </MobileMenuAccordion>
        ) : (
          <MobileMenuItem
            key={`_id-${key}`}
            $hover
            as={item.route ? 'button' : Link}
            to={item.link ? item.link : undefined}
            onClick={() => {
              setMobileMenu(false);
              item.onClick && item.onClick();
            }}
          >
            {t(item.title)}
          </MobileMenuItem>
        )
      )}
      <NavbarSignInButton />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  -webkit-animation: ${slideInFromLeft} 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${slideInFromLeft} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background: #fff;
  box-shadow: 0px 17px 10px 10px rgb(38 107 193 / 8%);
  max-width: 500px;
  overflow: scroll;
  position: fixed;
  right: 0;
  width: 80%;
  z-index: 100;
  ${RightMobileNavbarMediaQueries};
  ${screenGreaterThan('1142px')} {
    display: none;
  }

  button {
    margin: 1rem;
    position: relative;
    width: calc(100% - 2rem);
  }
`;

const MobileMenuAccordion = styled(Accordion)`
  box-shadow: none !important;
  &.MuiAccordion-root:before {
    opacity: 0;
  }
  &.MuiAccordion-root.Mui-expanded .MuiAccordionSummary-root,
  .MuiCollapse-container {
    background: #fff;
  }
  &.MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }
  .MuiAccordionSummary-root {
    display: flex;
    padding: 0px 30px;
    ${FONT_MEDIUM}
    &:hover {
      color: ${PRIMARY_COLOR};
      .MuiSvgIcon-root {
        color: ${PRIMARY_COLOR};
      }
    }
    &.Mui-expanded {
      min-height: auto;
    }
  }
  .MuiAccordionDetails-root {
    display: block;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

const MobileMenuItem = styled.button`
  background-color: inherit;
  border: none;
  color: inherit;
  display: block;
  font-size: inherit;
  margin: 0 0 5px 0 !important;
  padding: 10px 30px;
  text-align: start;
  text-decoration: none;
  &:hover &:focus &:active {
    color: inherit;
    text-decoration: none;
  }
  ${FONT_MEDIUM}
  ${(props) =>
    props.$hover &&
    css`
      cursor: pointer;
      &:hover {
        color: ${PRIMARY_COLOR};
      }
    `}
`;

MobileMenu.propTypes = {
  setMobileMenu: PropTypes.func,
};

export default MobileMenu;
