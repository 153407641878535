import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { P } from '../Text';
import {
  FONT_BOLD,
  FONT_MEDIUM,
  PRIMARY_COLOR,
  screenLessThan,
  SCREEN_PAD,
} from '../../shared/styles';

export const GDPA = styled.img`
  ${screenLessThan('500px')} {
    bottom: 0;
    position: relative;
    right: 0;
  }
`;

export const YellowCircleBackground = styled.img`
  filter: blur(70px);
  height: 400px;
  left: 6%;
  position: absolute;
  top: 0;
  width: 40%;
  z-index: -1;
  ${screenLessThan('700px')} {
    width: 35%;
  }
`;

export const FooterContainer = styled.footer`
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  position: relative;
  width: 100%;
  @media (max-width: 414px) {
    padding-bottom: 1rem;
  }
`;

export const FooterBizLogo = styled.img`
  height: 50px;
  margin-top: -12px;
  padding-bottom: 0rem;
  width: 10rem;
`;
export const FooterMargin = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 4.5rem;
  ${SCREEN_PAD}
  ${screenLessThan('901px')} {
    display: block;
    padding-top: 1rem;
  }
  .copyright {
    font-size: 12px;
  }
`;

export const FooterContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${SCREEN_PAD}
`;

export const FooterTitle = styled.p`
  ${FONT_BOLD};
  font-size: 20px;
  margin-bottom: ${({ isFirstColumn }) =>
    isFirstColumn ? '0.3rem' : '1.3rem'};
  padding-top: 1.2rem;
`;
export const FooterLinks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  width: 18%;
  ${screenLessThan('1300px')} {
    width: 25%;
  }
  ${screenLessThan('900px')} {
    width: 50%;
  }
  ${screenLessThan('425px')} {
    width: 100%;
  }

  > a {
    color: #000000;
    font-size: 14px;
    margin-bottom: 15px;
    text-decoration: none;
    ${FONT_MEDIUM}

    :hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

export const FooterEmailLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
  ${screenLessThan('1300px')} {
    width: 100%;
    ${(props) =>
      props.leftOnSm &&
      css`
        justify-content: flex-start;
      `}
  }
`;

export const FooterEmailLinksContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const FooterTestDriveTitle = styled(P)`
  ${FONT_BOLD}
`;

export const FooterNoPathText = styled.span`
  color: #000000;
  cursor: not-allowed;
  font-size: 14px;
  ${FONT_MEDIUM}
  margin-bottom: 15px;
`;

export const RequestDemoButton = styled(Button)`
  text-decoration: none;
  width: 15rem;
  ${screenLessThan('1300px')} {
    margin-bottom: 2rem;
  }
`;
