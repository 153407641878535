import React, { useRef, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import lottie from 'lottie-web/build/player/lottie_light';
import loaderAnimation from '../../../static/json/loader.json';

/**
 * Lottie Player
 * @param {String} className added to wrapper around the lottie
 * @param {String} src lottie path; (By default - Loader)
 * @param {String | Number} size height and width of lottie (by default - 100%)
 */
const Lottie = ({ className, src, style }) => {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: src || loaderAnimation,
      });

      return () => anim.current?.destroy();
    }
  }, [src]);

  return <div className={className} ref={animationContainer} style={style} />;
};

Lottie.propTypes = {
  className: string,
  src: PropTypes.object,
  style: PropTypes.object,
};

export default Lottie;
