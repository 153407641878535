import React, { useState, useEffect } from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import LottieHamburger from '../LottieHamburger';
import TransHTML from '../TransHTML';
import DropdownLayout from './DropdownLayout';
import LanguageToggle from './LanguageToggle';
import MobileMenu from './MobileMenu';
import NavbarItem from './NavbarItem';
import NavbarSignupButton from './NavbarSignupButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../shared/routes';
import { navbarMenu } from './Header.helper';

import { CONTACT_US_NUMBERS, SIGN_IN_URL } from '../../config';
import {
  HeaderBizLogo,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderLeft,
  HeaderMenu,
  HeaderRight,
  MobileMenuRight,
  NavbarLoginText,
  PhoneNumber,
  CallImg,
} from './Header.styles';

import BizLogoImg from '../../../static/svgs/home/biz_logo.svg';
import CallLogo from '../../../static/svgs/home/call.svg';

const menuKeys = ['solution', 'company_nature'];

const Header = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const [offset, setOffset] = useState(0);
  const [activeMenu, setActiveMenu] = useState('');
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleDropdown = (menuKey) => {
    if (activeMenu) setActiveMenu('');
    else setActiveMenu(menuKey);
  };

  const handleCloseMenu = () => setActiveMenu('');
  return (
    <HeaderContainer showBoxShadow={offset > 0}>
      <HeaderInnerContainer>
        <HeaderLeft>
          <Link to="/" onClick={handleCloseMenu}>
            <HeaderBizLogo src={BizLogoImg} alt="biz" width={107} height={56} />
          </Link>
        </HeaderLeft>
        <HeaderMenu>
          {navbarMenu.map((item, key) => (
            <NavbarItem
              key={key}
              activeMenu={activeMenu}
              handleDropdown={handleDropdown}
              {...item}
            />
          ))}
        </HeaderMenu>

        {!mobileMenu && menuKeys.includes(activeMenu) && (
          <DropdownLayout
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )}

        <HeaderRight>
          {offset > 0 ? (
            <PhoneNumber tel={t(CONTACT_US_NUMBERS.QATAR)}>
              <CallImg src={CallLogo} />
              <TransHTML contentKey={t('header-menu.Sales')} />
            </PhoneNumber>
          ) : (
            ''
          )}
          <LanguageToggle offset={offset} isMobile={false} />
          <NavbarLoginText to={SIGN_IN_URL}>
            {t('header-menu.login')}
          </NavbarLoginText>
          <NavbarSignupButton />
        </HeaderRight>

        <MobileMenuRight>
          <NavbarItem
            offset={width}
            menuKey="get-in-touch"
            title="header-menu.get_in_touch"
            link={ROUTES.getInTouch}
          />
          <LottieHamburger open={mobileMenu} setOpen={setMobileMenu} />
        </MobileMenuRight>
      </HeaderInnerContainer>

      {mobileMenu && <MobileMenu setMobileMenu={setMobileMenu} />}
    </HeaderContainer>
  );
};

export default Header;
