import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '../../../static/json/menu.json';

/**
 * Animated lottie for Hamburger Menu
 */
const LottieHamburger = ({ open, setOpen }) => {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData,
      });

      return () => anim.current?.destroy();
    }
  }, []);

  useEffect(() => {
    anim.current?.setDirection(!open ? -1 : 1);
    anim.current?.play();
  }, [open]);

  return (
    <StyledIcon
      aria-label="lottie-hamburger"
      onClick={() => setOpen(!open)}
      ref={animationContainer}
    />
  );
};

const StyledIcon = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 35px;
  padding: 0;
  width: 35px;
`;

LottieHamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default LottieHamburger;
