import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../Button';
import { RedirectToSSOSignIn } from '../../util';
import { screenLessThan } from '../../shared/styles';
import lockImg from '../../../static/svgs/home/lock-white.svg';

const NavbarSignInButton = () => {
  const { t } = useTranslation();
  return (
    <StyledRoundButton
      className="ml-1 btn__nav-sign-in"
      onClick={RedirectToSSOSignIn}
    >
      {t('header-menu.signin')}
      <img src={lockImg} alt="lock" />
    </StyledRoundButton>
  );
};

const StyledRoundButton = styled(Button)`
  img {
    margin-left: 10px;
    width: 11px;
    height: 13px;
  }
  ${screenLessThan('1600px')} {
    height: 2.5rem;
  }
  ${screenLessThan('1142px')} {
    margin-right: 10px;
  }
`;

export default NavbarSignInButton;
