import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../shared/styles';

export const StyledLink = styled.a`
  color: #000000;
  text-decoration: none;
  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;
