exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-accounting-and-billing-software-for-business-js": () => import("./../../../src/pages/features/accounting-and-billing-software-for-business.js" /* webpackChunkName: "component---src-pages-features-accounting-and-billing-software-for-business-js" */),
  "component---src-pages-features-customer-management-crm-software-for-businesses-js": () => import("./../../../src/pages/features/customer-management-crm-software-for-businesses.js" /* webpackChunkName: "component---src-pages-features-customer-management-crm-software-for-businesses-js" */),
  "component---src-pages-features-deals-management-software-for-business-js": () => import("./../../../src/pages/features/deals-management-software-for-business.js" /* webpackChunkName: "component---src-pages-features-deals-management-software-for-business-js" */),
  "component---src-pages-features-estimates-management-software-for-business-js": () => import("./../../../src/pages/features/estimates-management-software-for-business.js" /* webpackChunkName: "component---src-pages-features-estimates-management-software-for-business-js" */),
  "component---src-pages-features-inventory-management-software-for-business-js": () => import("./../../../src/pages/features/inventory-management-software-for-business.js" /* webpackChunkName: "component---src-pages-features-inventory-management-software-for-business-js" */),
  "component---src-pages-features-invoice-management-software-for-business-js": () => import("./../../../src/pages/features/invoice-management-software-for-business.js" /* webpackChunkName: "component---src-pages-features-invoice-management-software-for-business-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-lead-management-software-for-businesses-js": () => import("./../../../src/pages/features/lead-management-software-for-businesses.js" /* webpackChunkName: "component---src-pages-features-lead-management-software-for-businesses-js" */),
  "component---src-pages-features-purchase-order-management-system-for-sme-js": () => import("./../../../src/pages/features/purchase-order-management-system-for-sme.js" /* webpackChunkName: "component---src-pages-features-purchase-order-management-system-for-sme-js" */),
  "component---src-pages-features-vendor-management-software-for-businesses-js": () => import("./../../../src/pages/features/vendor-management-software-for-businesses.js" /* webpackChunkName: "component---src-pages-features-vendor-management-software-for-businesses-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-get-in-touch-oman-event-js": () => import("./../../../src/pages/get-in-touch-oman-event.js" /* webpackChunkName: "component---src-pages-get-in-touch-oman-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */)
}

