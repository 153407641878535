import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink } from './Links.styles';

/**
 * Link to trigger call
 * @param {Number} tel phone number
 * You can wrap this component to the anything custom that needs to act as link
 * If not; by default it will wrap in number itself
 */
const CallLink = ({ tel, children, ...props }) => (
  <StyledLink {...props} href={`tel:${tel}`}>
    {children ?? tel}
  </StyledLink>
);

CallLink.propTypes = {
  tel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
};

export default CallLink;
