import { css } from 'styled-components';

export const FONT_REGULAR = css`
  font-family: 'Gilroy', 'Frutiger', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
`;
export const FONT_MEDIUM = css`
  font-family: 'Gilroy', 'Frutiger', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500;
`;
export const FONT_BOLD = css`
  font-family: 'Gilroy', 'Frutiger', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 700;
`;
export const FONT_BOLDER = css`
  font-family: 'Gilroy', 'Frutiger', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 800;
`;
