import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import styled from 'styled-components';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../Button';
import Lottie from '../Lottie';
import { P, PMedium } from '../Text';
import TransHTML from '../TransHTML';
import { RedirectToSSOSignUp } from '../../util';
import { FONT_BOLD } from '../../shared/styles';
import popupLottie from '../../../static/json/announcement.json';
import closeImg from '../../../static/svgs/common/close-black.svg';

const ModalStates = {
  ALREADY_SHOWN: 'shown',
};

/**
 * A Popup that fires when user is idle in website
 * Connected to Google Analytics
 */
const IdlePopup = () => {
  const { t } = useTranslation();
  const [onTriggerModal, setOnTriggerModal] = useState(false);

  const onPrompt = async () => {
    const popup = await localStorage.getItem('popup');
    if (popup === ModalStates.ALREADY_SHOWN) return;
    else {
      setOnTriggerModal(true);
    }
  };

  const handleClose = () => {
    setOnTriggerModal(false);
    localStorage.setItem('popup', ModalStates.ALREADY_SHOWN);
  };

  const handleAcceptClick = () => {
    RedirectToSSOSignUp();
    localStorage.setItem('popup', ModalStates.ALREADY_SHOWN);
  };
  useIdleTimer({
    onPrompt,
    timeout: 1000 * 2 * 60,
    promptTimeout: 10,
    stopOnIdle: true,
    startOnMount: true,
    name: 'idle-timer',
  });

  return (
    <Dialog id="biz_popup_signin" open={onTriggerModal} disableEscapeKeyDown>
      <StyledDialogContent>
        <StyledClose
          id="biz_signup_cancel_via_idle_popup"
          src={closeImg}
          onClick={handleClose}
          alt="close"
        />
        <PMedium className="title" align="center">
          {t('idle_popup.title')}
        </PMedium>
        <P align="center">
          <TransHTML contentKey={t('idle_popup.subtitle')} />
        </P>
        <Lottie
          className="lottie-wrapper"
          style={{
            width: '20rem',
            height: '20rem',
            marginBottom: '1rem',
          }}
          src={popupLottie}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button id="biz_signup_via_idle_popup" onClick={handleAcceptClick}>
          {t('idle_popup.button')}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

const StyledClose = styled.img`
  background: #e7e8f3;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  padding: 8px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 13px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 3rem 0 !important;
  .title {
    ${FONT_BOLD}
  }
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center !important;
  padding: 0 3rem 3rem !important;
  button {
    min-width: 12rem;
  }
`;

export default IdlePopup;
