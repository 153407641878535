import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'gatsby-plugin-react-i18next';

/**
 * Translation Component customised with HTML
 * @param {String} contentKey The Translation "key" string
 */
const TransHTML = ({ contentKey }) => (
  <Trans
    i18nKey={contentKey}
    components={{
      li: <li />,
      ul: <ul />,
      ol: <ol />,
      br: <br />,
      bold: <strong />,
      italics: <em />,
    }}
  />
);

TransHTML.propTypes = {
  contentKey: PropTypes.string.isRequired,
};

export default TransHTML;
