import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { ExternalLink } from '../Links';
import { socialLinks } from './Footer.helper';

const FooterIcons = () => (
  <FooterIconsWrapper>
    {socialLinks.map(({ link, Icon }, key) => (
      <ExternalLink key={key} url={link} className="btn__footer-social">
        <LazyLoad height={28}>{Icon}</LazyLoad>
      </ExternalLink>
    ))}
  </FooterIconsWrapper>
);

const FooterIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  img {
    height: 28px;
    margin-right: 10px;
    width: 28px;
  }
`;

export default FooterIcons;
