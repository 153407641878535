import React from 'react';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import { map } from 'underscore';
import EmailInput from '../EmailInput';
import { ExternalLink, MailLink } from '../Links';
import FooterIcons from './FooterIcons';
import { ROUTES } from '../../shared/routes';
import { footerLinks } from './Footer.helper';
import { COPYRIGHT_YEAR } from '../../config';
import {
  FooterBizLogo,
  FooterContainer,
  FooterContent,
  FooterEmailLinks,
  FooterEmailLinksContent,
  FooterLinks,
  FooterMargin,
  FooterNoPathText,
  FooterTestDriveTitle,
  FooterTitle,
  YellowCircleBackground,
  RequestDemoButton,
  GDPA,
} from './Footer.styles';
import BizLogoSrc from '../../../static/svgs/home/BizFooterLogo.svg';
import GDPR from '../../../static/svgs/home/GDPR.svg';
import yellowCircle from '../../../static/svgs/home/yellowCircle.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <YellowCircleBackground src={yellowCircle} alt="yellow-circle" />
      <FooterContent>
        {map(
          footerLinks,
          ({ footerTitle, footerSubLinks, isFirstColumn }, key) => (
            <FooterLinks key={key}>
              <FooterTitle isFirstColumn={isFirstColumn}>
                {isFirstColumn ? (
                  <FooterBizLogo src={BizLogoSrc} alt="footer-biz-logo" />
                ) : (
                  t(footerTitle)
                )}
              </FooterTitle>

              {footerSubLinks.map((footerLink, key) =>
                footerLink.isExternal ? (
                  <ExternalLink
                    key={key}
                    url={footerLink.path}
                    className="btn__footer-external"
                  >
                    {t(footerLink.text)}
                  </ExternalLink>
                ) : footerLink.isMail ? (
                  <MailLink
                    key={key}
                    mail={footerLink.text}
                    className="btn__footer-mail"
                  />
                ) : footerLink.path ? (
                  <Link
                    key={key}
                    to={footerLink.path}
                    className="btn__footer-btn"
                  >
                    {t(footerLink.text)}
                  </Link>
                ) : (
                  <FooterNoPathText key={key}>
                    {t(footerLink.text)}
                  </FooterNoPathText>
                )
              )}
              {isFirstColumn && <FooterIcons />}
            </FooterLinks>
          )
        )}
        <FooterEmailLinks leftOnSm>
          <FooterEmailLinksContent>
            <FooterTestDriveTitle margin="0">
              {t('test-drive')}
            </FooterTestDriveTitle>
            <EmailInput />
          </FooterEmailLinksContent>
        </FooterEmailLinks>
      </FooterContent>

      <FooterMargin>
        <RequestDemoButton as={Link} to={ROUTES.requestDemo}>
          {t('footer.demo')}
        </RequestDemoButton>

        <p className="copyright">
          {t('footer.copyright', { year: COPYRIGHT_YEAR })}
        </p>
        <GDPA src={GDPR} alt="gdpa-compliant" />
      </FooterMargin>
    </FooterContainer>
  );
};

export default Footer;
