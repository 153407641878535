import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  FONT_BOLD,
  FONT_BOLDER,
  FONT_MEDIUM,
  screenLessThan,
} from '../../shared/styles';

/**
 * Accepted custom props along with default props
 * @param {string} align for text align property
 * @param {string} margin for margin shorthand property
 */
const H1 = ({ align, margin, children, ...props }) => (
  <H1Text align={align} margin={margin} {...props}>
    {children}
  </H1Text>
);
const H2 = ({ align, margin, children, ...props }) => (
  <H2Text align={align} margin={margin} {...props}>
    {children}
  </H2Text>
);
const H3 = ({ align, margin, children, ...props }) => (
  <H3Text align={align} margin={margin} {...props}>
    {children}
  </H3Text>
);
const P = ({ align, margin, children, ...props }) => (
  <Para align={align} margin={margin} {...props}>
    {children}
  </Para>
);
const PMedium = ({ align, margin, children, ...props }) => (
  <Para1 align={align} margin={margin} {...props}>
    {children}
  </Para1>
);

export { H1, H2, H3, PMedium, P };

const H1Text = styled.h1`
  font-size: 3.8rem;
  ${FONT_BOLDER}
  ${(props) => props.align && `text-align: ${props.align}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${screenLessThan('1500px')} {
    font-size: 52px;
  }
  ${screenLessThan('1000px')} {
    font-size: 2.7rem;
  }
  ${screenLessThan('600px')} {
    font-size: 38px;
  }
`;

const H2Text = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
  ${FONT_BOLD};
  text-align: ${(props) => props.align || 'center'};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${screenLessThan('1500px')} {
    font-size: 54px;
  }
  ${screenLessThan('600px')} {
    font-size: 28px;
  }
`;

const H3Text = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;
  ${FONT_BOLD}
  text-align: ${(props) => props.align || 'center'};
  ${(props) => props.margin && `margin: ${props.margin}`};

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

const Para1 = styled.p`
  font-size: 1.5rem;
  ${FONT_MEDIUM};
  ${(props) => props.align && `text-align: ${props.align}`};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${screenLessThan('1500px')} {
    font-size: 20px;
  }
`;

const Para = styled.p`
  font-size: 16px;
  margin-bottom: 2rem;
  text-align: ${(props) => props.align || 'center'};
  ${(props) => props.margin && `margin: ${props.margin}`};
`;

const TextPropTypes = {
  align: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.node,
};
H1.propTypes = TextPropTypes;
H2.propTypes = TextPropTypes;
H3.propTypes = TextPropTypes;
P.propTypes = TextPropTypes;
PMedium.propTypes = TextPropTypes;
