/* eslint-disable import/no-unused-modules */
export function shouldUpdateScroll() {
  window.scrollTo(0, 0);
  const body = document.getElementsByTagName('body')[0];
  body.scrollTop = 0;
  return false;
}
const appendScript = (options) => {
  const scriptElement = document.createElement('script');
  Object.keys(options).forEach((key) => {
    scriptElement.setAttribute(key, options[key]);
  });

  document.head.appendChild(scriptElement);
};

const loadedScripts = 'loadedScripts';

const appendThirdPartyScripts = () => {
  appendScript({
    src: 'https://leadbooster-chat.pipedrive.com/assets/loader.js',
  });

  // Variable set to reference as a check that callback ran
  sessionStorage.setItem(loadedScripts, 'true');
};

const appendScriptsOnScrollOptions = { once: true };
export const onInitialClientRender = () => {
  window.addEventListener(
    'scroll',
    appendThirdPartyScripts,
    appendScriptsOnScrollOptions
  );
};
export const onRouteUpdate = ({ prevLocation }) => {
  const checkLoadedScripts = sessionStorage.getItem(loadedScripts);

  // If first navigation update and user didn't scroll, append
  // scripts
  if (prevLocation && !checkLoadedScripts) {
    // Prevent appending scripts on scroll
    window.removeEventListener(
      'scroll',
      appendThirdPartyScripts,
      appendScriptsOnScrollOptions
    );
    appendThirdPartyScripts();
  }
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;
  setTimeout(() => {
    if (typeof gtag === 'function') {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};
