import React from 'react';

// eslint-disable-next-line import/no-unused-modules, react/prop-types
export const ArrowDown = ({ color = '#181818' }) => (
  <svg
    className="arrow-down"
    xmlns="http://www.w3.org/2000/svg"
    width="7.295"
    height="5.221"
    viewBox="0 0 7.295 5.221"
  >
    <path
      id="Down_Arrow"
      data-name="Down Arrow"
      d="M.88,1.52C1,1.68,3.17,5.23,3.49,4.66,4.18,3.43,5.5,2.52,6.12,1.27"
      transform="translate(0.17 -0.265)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
