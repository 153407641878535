import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import LanguageToggle from './LanguageToggle';
import { FONT_MEDIUM, PRIMARY_COLOR } from '../../shared/styles';

import { ArrowDown } from '../../../static/customised-svgs/arrow-down';
import { ArrowUp } from '../../../static/customised-svgs/arrow-up';

const NavbarItem = ({
  offset,
  title,
  menuKey,
  link,
  activeMenu,
  handleDropdown,
  className,
}) => {
  const { t } = useTranslation();
  const handleDropdownClick = () => (menuKey ? handleDropdown(menuKey) : null);

  return (
    <NavbarDropdownContainer
      as={link ? Link : 'button'}
      onClick={link ? undefined : handleDropdownClick}
      to={link ? link : undefined}
      $isActive={activeMenu === menuKey}
      className={className}
    >
      {offset < 1150 ? <LanguageToggle /> : t(title)}
      {!link && (activeMenu === menuKey ? <ArrowUp /> : <ArrowDown />)}
    </NavbarDropdownContainer>
  );
};

const NavbarDropdownContainer = styled.button`
  align-self: center;
  background-color: inherit;
  border: none;
  color: ${({ $isActive }) => ($isActive ? PRIMARY_COLOR : 'inherit')};
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  margin: 0 12px;
  padding: 0;
  position: relative;
  text-decoration: none;
  ${FONT_MEDIUM};

  svg {
    margin-bottom: 2px;
    margin-left: 5px;
  }
  :hover {
    color: ${PRIMARY_COLOR};
    svg.arrow-down path {
      stroke: ${PRIMARY_COLOR};
    }
  }
`;

NavbarItem.propTypes = {
  offset: PropTypes.number,
  title: PropTypes.string,
  menuKey: PropTypes.string,
  activeMenu: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
  handleDropdown: PropTypes.func,
};

NavbarItem.defaultProps = {
  className: 'nav__header-item',
};

export default NavbarItem;
